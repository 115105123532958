import React from 'react';
import Obfuscate from 'react-obfuscate';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Imprint = props => (
  <Layout bodyClass="page-imprint">
    <SEO title="Imprint" />
    <div className="container">
      <div className="row">
        <header className="col-12">
          <h1>Imprint</h1>
        </header>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>WXT MEDIA // <br />
          Konzeption, Gestaltung &amp; Umsetzung digitaler Medien</h3>
          <p>
            <strong>
              <span className="gradient1 biggerFont">Ines Opifanti</span>
            </strong>
            <br />
            <i>Dipl. Designerin (Fotodesign) FH</i>
            <br />
            Dorfstrasse 18
            <br />
            D-22941 Jersbek
            <br />
            Germany
          </p>

          <p>
            MOBIL:
{' '}
<Obfuscate tel="+49 176 23 19 58 88" />
            <br />
            E-MAIL:
{' '}
<Obfuscate email="hi@wxt.media" />
          </p>

          <p>
            Finanzamt Stormarn
            <br />
            USt-IdNr.: DE 275697512
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Imprint;
